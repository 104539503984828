import "./reset.css"
import "./grid.css"
import "./flow.css"
import "./functional.css"
import "./icons.css"

import "./static/assets/opensans/opensans.css"
import "./vars.css"
import "./body.css"
import "./admin.css"

import "./buttons.css"
import "./f3cc.css"
import "./details.css"
import "./embeds.css"
import "./faq.css"
import "./footer.css"
import "./header.css"
import "./messages.css"
import "./prose.css"
import "./reasons.css"

import { setDetailsHeights } from "./details.js"
import { onReady, qs } from "./utils.js"

import "./forms.css"
import { initForms } from "./forms.js"
onReady(initForms)

import "./textareas.css"
import { initTextareas } from "./textareas.js"
onReady(initTextareas)

onReady(() => {
  setDetailsHeights("details")
  window.addEventListener("load", () => setDetailsHeights("details"))
})

onReady(() => {
  // Normal browsers do this by default. Samsung needs help
  let hash = window.location.hash,
    el = hash && qs(hash)
  if (el) {
    el.scrollIntoView()
    setTimeout(() => el.scrollIntoView(), 250)
    window.addEventListener("load", () => el.scrollIntoView())
  }
})

onReady(() => {
  let el = qs("#scroll-into-view")
  if (el) {
    el.scrollIntoView()
  }
})
