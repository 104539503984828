export function setDetailsHeights(selector, wrapper = document) {
  const setHeights = (details) => {
    const initiallyOpen = details.open
    details.dataset.width = details.clientWidth
    details.open = true
    details.style.setProperty("--expanded", `${details.clientHeight}px`)
    details.open = false
    details.style.setProperty("--collapsed", `${details.clientHeight}px`)
    details.open = initiallyOpen
  }
  const allDetails = wrapper.querySelectorAll(selector)
  const RO = new ResizeObserver((entries) => {
    return entries.forEach((entry) => {
      const details = entry.target
      const width = parseInt(details.dataset.width, 10)
      if (width !== details.clientWidth) {
        details.removeAttribute("style")
        setHeights(details)
      }
    })
  })
  allDetails.forEach((details) => {
    RO.observe(details)
  })
}
